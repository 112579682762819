/* Error page specific styles */
.error-body {
    margin-top: 100px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #727272;
    line-height: 1.6;
    text-align: center;
}

.error-container {
    padding-top: 150px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center; /* Center-align content */
    padding-bottom: 250px; /* Add space at the bottom */
}


.error-subtitle {
    margin-top: 50px;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0.1em;
    color: #A299AC;
    text-transform: uppercase;
}

.error-text {
    font-size: 16px;
    margin: 1em 0;
    color: #727272;
}

/* Style for the "Go Back Home" link */
.error-container a.error-link {
    display: inline-block;
    margin-top: 2em;
    padding: 10px 20px;
    color: #1db954;
    font-weight: 700;
    border: 2px solid #1db954;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-radius: 5px;
    transition: color 0.3s ease, border-color 0.3s ease;
}

.error-container a.error-link:hover {
    color: #1ed760; /* Hover color */
    border-color: #1ed760;
}

@media screen and (max-width: 480px) {
    .error-title {
        font-size: 36px;
    }

    .error-subtitle {
        font-size: 18px;
    }

    .error-container a.error-link {
        font-size: 14px;
        padding: 8px 16px;
    }
}
