/* src/components/GitHubSnippet.css */

.github-snippet {
    text-align: center;
    margin-top: 10px; /* Adjusts spacing around section */
  }
  
  .github-snippet h2 {
    margin-bottom: 4px; /* Controls space between heading and link */
  }
  
  .github-snippet a {
    display: inline-block;
    margin-bottom: 4px; /* Reduces space between link and iframe */
  }
  
  .github-iframe {
    width: 100%; /* Adjusts iframe width */
    height: 155px; /* Adjusts iframe height */
    border: none;
  }
  