/* Ensure the container takes full height without unnecessary space */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Ensure the iframe stays close to the top */
    min-height: 100vh; /* Full viewport height */
    padding-top: 70px; /* Adjust to center the iframe */
    padding-bottom: -500px;
    box-sizing: border-box;
    overflow: hidden; /* Hide any overflow */
}

/* iFrame styling */
.form-iframe {
    width: 900px;
    height: 1450px;
    border: none;
    overflow: hidden;
}

footer {
    margin-top: 0; /* Push the footer to the bottom */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .form-container {
        padding-top: 50px; /* Adjust to center the iframe */
        min-height: auto; /* Let the container expand naturally */
    }

    .form-iframe {
        width: 100%; /* Full width for smaller screens */
        height: 2000px;
    }
}
