/* src/components/GitHubSnippet.css */

.github-snippet {
    text-align: center;
    margin-top: 10px; /* Adjusts spacing around section */
  }
  
  
.github-snippet a {
    color: white;
    display: inline-block;
    margin-bottom: 4px; /* Reduces space between link and iframe */
    font-family: 'Arial', sans-serif; /* Sets the font family for h2 */
    font-size: 24px; /* Sets the font size for h2 */
    font-weight: bold; /* Sets the font weight for h2 */
}
  
  .music-iframe {
    width: 100%; /* Adjusts iframe width */
    height: 70px; /* Adjusts iframe height */
    border: none;
  }
  