/* General styling */
.resume-page {
    color: #1a202c;
    min-height: 100vh;
    padding: 50px 0;
    padding-top: 100px;
  }
  
  .resume-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Timeline container */
  .timeline {
    position: relative;
    border-left: 1.5px solid #e2e8f0;
    padding-left: 50px;
  }
  
  /* Individual timeline item */
  .timeline-item {
    margin-bottom: 40px;
    position: relative;
  }
  
  .timeline-item .icon {
    position: absolute;
    top: -5px;
    left: -75px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #acaeb1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeline-item .icon-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  /* Content styling */
  .content {
    background-color: #092e32;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .content .title {
    font-size: 18px;
    font-weight: bold;
    color: #eff0f2;
  }
  
  .content .organization {
    font-size: 16px;
    color: #e8ebe5;
  }
  
  .content .duration {
    font-size: 14px;
    color: #c5c8cd;
    margin-bottom: 10px;
  }
  
  .content .description {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    list-style-position: inside;
    color: #c5c7c9;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .timeline {
      padding-left: 20px;
    }
  
    .timeline-item .icon {
      left: -30px;
    }
  }
  